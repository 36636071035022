import React, { useState } from 'react';
import axios from 'axios';
import joi from 'joi';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';

import truckImg from '../../../assets/images/FuelComponent/twoTrucks.png';
import twoTrucksTablet from '../../../assets/images/FuelComponent/twoTrucksTablet.jpg';
import contactMap from '../../../assets/images/Contact/contactMap.jpg';
import MapInfoComponent from '../../common/MapInfoComponentV2/MapInfoComponent';
import './index.css';

const contactLocations = [
  {
    name: 'BIROU GALATI 1',
    phoneNumber: '0740 232 232',
    location: { lat: 45.46, lng: 28.03 },
    address: 'Str. Clabucet, nr. 3-5, Galati, Galati',
  },
  {
    name: 'BIROU GALATI 2',
    phoneNumber: '0741239050',
    location: { lat: 45.44, lng: 28.02 },
    address: 'Str. Lozoveni , nr. 233, Galati, Galati',
  },
  {
    name: 'BIROU Targu Bujor',
    phoneNumber: '+40 751 234 303',
    location: { lat: 45.87, lng: 27.91 },
    address: 'Str. Mecanizatorilor, nr. 5B, Targu Bujor Galati',
  },
  {
    name: 'BIROU Bacau',
    phoneNumber: '+40 756 058 896',
    location: { lat: 46.54, lng: 26.9 },
    address: 'Str. Alexei Tolstoi, nr. 67, Bacau, Bacau',
  },
  {
    name: 'BIROU Ianca',
    phoneNumber: '+40 756 058 898',
    location: { lat: 45.13, lng: 27.51 },
    address: 'Nr 38, Sat Gara Ianca, Ianca, Braila',
  },
  {
    name: 'DEPOZIT IANCA',
    phoneNumber: '+40 756 058 898',
    location: { lat: 45.13, lng: 27.51 },
    address: 'Nr 38, Sat Gara Ianca, Ianca, Braila',
  },
  {
    name: 'DEPOZIT BACAU',
    phoneNumber: '+40 756 058 896',
    location: { lat: 46.54, lng: 26.9 },
    address: 'Str. Alexei Tolstoi, nr. 67, Bacau, Bacau',
  },
  {
    name: 'DEPOZIT TG BUJOR',
    phoneNumber: '+40 756 078 082 / +40 753 111 190',
    location: { lat: 45.87, lng: 27.91 },
    address: 'Str. Mecanizatorilor, nr. 5B, Targu Bujor Galati',
  },
];

const contactData = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  'g-recaptcha-response': '',
};

const contactInputErrors = {
  firstname: {
    error: 'Vă rugăm să vă introduceți prenumele.',
    errored: false,
  },
  lastname: {
    error: 'Vă rugăm să vă introduceți numele.',
    errored: false,
  },
  email: {
    error: 'Vă rugăm să introduceți un e-mail valid.',
    errored: false,
  },
  phone: {
    error: 'Vă rugăm să introduceți un număr de telefon valid.',
    errored: false,
  },
  message: {
    error: 'Vă rugăm să introduceți mesajul dorit.',
    errored: false,
  },
  'g-recaptcha-response': {
    error: 'Vă rugăm să completați recaptcha.',
    errored: false,
  },
};

export default () => {
  // The contact schema written with Yup.
  let contactSchema = joi.object({
    firstname: joi.string().required(),
    lastname: joi.string().required(),
    email: joi
      .string()
      .email({ tlds: { allow: false } })
      .required(),
    phone: joi.string().length(10).required(),
    message: joi.string().required().min(30),
    'g-recaptcha-response': joi.string().required(),
  });

  const [contactState, setContactState] = useState(contactData);
  const [contactSent, setContactSent] = useState(false);
  const [contactInputErrorsState, setContactInputErrorsState] =
    useState(contactInputErrors);

  // contact form validation function
  function validateContactForm(contactFormData) {
    // make a copy of the form error state object.
    const _contactInputErrorsState = { ...contactInputErrorsState };

    // before anything happens, let's verify for errors.
    // this returns all errors, if any.
    const validationErrors = contactSchema.validate(contactFormData, {
      abortEarly: false,
    });

    // pluck out all errored input names (joi helps with that)
    const erroredKeys = validationErrors.error?.details.map((err) => {
      return err.path[0];
    });

    // figure out if above keys can be found against each input element.
    // if found - set errored to true.
    // otherwise - set errored to false.
    for (const key in _contactInputErrorsState) {
      let findErr = erroredKeys?.find((it) => it === key);
      if (findErr) _contactInputErrorsState[key].errored = true;
      else _contactInputErrorsState[key].errored = false;
    }

    // set new object error state.
    setContactInputErrorsState(_contactInputErrorsState);

    return erroredKeys;
  }

  function sendContactData(ev) {
    // turn back message sent flag to false.
    setContactSent(false);
    const _contactState = { ...contactState };

    // validate our form against the schema
    const errored = validateContactForm(_contactState);

    if (errored && errored.length > 0) {
      toast('Va rugam completati cu atentie formularul.');
      return;
    }

    axios
      .post(`${window.__APP_CONFIG__.apiUrl}/contact/form`, _contactState)
      .then(function (response) {
        if (response.data.ok) {
          toast('Mesajul dumneavoastra a fost trimis cu succes.');
          setContactState({
            ...contactData,
          });
          setContactInputErrorsState(contactInputErrors);
          setContactSent(true);
        } else {
          toast.error('');
        }
      })
      .catch(function (error) {
        console.error(
          '### Error while trying to send contact form to server : ',
          error,
          error.response?.status
        );
        if (error.response?.status == 429) {
          toast.error(
            'Ati facut prea multe cereri de acest fel. Va rugam asteptati.'
          );
          return;
        }
        toast.error('Eroare la trimitere.');
      });
  }

  const desktopBanner = {
    backgroundImage: `url(${truckImg})`,
  };
  const tabletBanner = {
    backgroundImage: `url(${twoTrucksTablet})`,
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page-content bg-white contactBanner">
        <div
          className="dz-bnr-inr dz-bnr-inr-md text-center overlay-black-middle backroungImage"
          style={window.innerWidth >= 988 ? desktopBanner : tabletBanner}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              <h1 className="text-white contactTitle">Contact</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="content-inner contactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 m-b30">
              <div className="section-head style-1">
                <h6 className="sub-title text-primary">Contactează-ne</h6>
                <h2 className="title formTitle">Informații</h2>
              </div>
              <div className="icon-bx-wraper left m-b40 style-7">
                <div className="icon-bx-sm">
                  <span className="icon-cell text-primary">
                    <i className="flaticon-phone-call"></i>
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="dz-title formSubtitle">Telefon</h4>
                  <p className="formText">+40 (336) 408 977</p>
                </div>
              </div>
              <div className="icon-bx-wraper left m-b40 style-7">
                <div className="icon-bx-sm">
                  <span className="icon-cell text-primary">
                    <i className="flaticon-placeholder"></i>
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="dz-title formSubtitle">Adresă</h4>
                  <p className="formText">
                    Str. Lozoveni, Nr. 233, Galați, Galați
                  </p>
                </div>
              </div>
              <div className="icon-bx-wraper left style-7">
                <div className="icon-bx-sm">
                  <span className="icon-cell text-primary">
                    <i className="flaticon-mail"></i>
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="dz-title formSubtitle  ">Email</h4>
                  <p className="formText">contact@rojevas.ro</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 m-b30">
              <div
                className="contact-area aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <form className="dz-form dzForm">
                  <div className="row sp10">
                    <div className="col-sm-6 m-b20">
                      <div className="input-group">
                        <input
                          value={contactState.lastname}
                          onChange={(e) =>
                            setContactState({
                              ...contactState,
                              lastname: e.currentTarget.value,
                            })
                          }
                          type="text"
                          className="form-control"
                          name="dzOther[first_name]"
                          placeholder="Nume"
                          style={
                            contactInputErrorsState.lastname.errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                        />
                      </div>
                      {contactInputErrorsState.lastname.errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrorsState.lastname.error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-6 m-b20">
                      <div className="input-group">
                        <input
                          value={contactState.firstname}
                          onChange={(event) =>
                            setContactState({
                              ...contactState,
                              firstname: event.currentTarget.value,
                            })
                          }
                          type="text"
                          className="form-control"
                          name="dzOther[last_name]"
                          placeholder="Prenume"
                          style={
                            contactInputErrorsState.firstname.errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                        />
                      </div>
                      {contactInputErrorsState.firstname.errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrors.firstname.error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-6 m-b20">
                      <div className="input-group">
                        <input
                          value={contactState.email}
                          onChange={(e) =>
                            setContactState({
                              ...contactState,
                              email: e.currentTarget.value,
                            })
                          }
                          type="text"
                          className="form-control"
                          name="dzEmail"
                          placeholder="Adresa Email"
                          style={
                            contactInputErrorsState.email.errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                        />
                      </div>
                      {contactInputErrorsState.email.errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrors.email.error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-6 m-b20">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="dzOther[phone_number]"
                          placeholder="Telefon"
                          value={contactState.phone}
                          onChange={(e) =>
                            setContactState({
                              ...contactState,
                              phone: e.currentTarget.value,
                            })
                          }
                          style={
                            contactInputErrorsState.phone.errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                        />
                      </div>
                      {contactInputErrorsState.phone.errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrors.phone.error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-12 m-b20">
                      <div className="input-group">
                        <textarea
                          name="dzMessage"
                          className="form-control"
                          value={contactState.message}
                          onChange={(e) =>
                            setContactState({
                              ...contactState,
                              message: e.currentTarget.value,
                            })
                          }
                          style={
                            contactInputErrorsState.message.errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                        >
                          Mesaj
                        </textarea>
                      </div>
                      {contactInputErrorsState.message.errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrors.message.error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-12 m-b20">
                      <div className="input-group">
                        <ReCAPTCHA
                          style={
                            contactInputErrorsState['g-recaptcha-response']
                              .errored
                              ? { borderColor: '#D52C2B' }
                              : {}
                          }
                          sitekey={window.__APP_CONFIG__.recaptcha2SiteKey}
                          onChange={(recaptchaVal) => {
                            setContactState({
                              ...contactState,
                              'g-recaptcha-response': recaptchaVal,
                            });
                          }}
                        />
                      </div>
                      {contactInputErrorsState['g-recaptcha-response']
                        .errored ? (
                        <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                          {contactInputErrors['g-recaptcha-response'].error}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-sm-12"></div>
                    <div className="col-sm-12">
                      <button
                        onClick={(ev) => {
                          ev.preventDefault();
                          sendContactData();
                        }}
                        className="btn btn-secondary btnContainer"
                      >
                        <span className="btnText">Trimite mesaj</span>
                      </button>
                    </div>
                    {contactSent ? (
                      <span style={{ color: '#119203', fontSize: '13px' }}>
                        Mesajul a fost trimis cu succes !
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MapInfoComponent img={contactMap} locations={contactLocations} />
    </>
  );
};
