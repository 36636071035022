import React from 'react';
import './index.css';
import gasTankBlue from '../../../assets/images/GasTank/gasTankBlue.jpg';
import gasTankSingle from '../../../assets/images/GasTank/gasTankSingle.jpg';
import gasTankRojevas from '../../../assets/images/GasTank/gasTanksRojevas.jpg';

const SliderGallery = () => {
  return (
    <>
      <section className="content-inner-2 service-area-1 paddingTop sliderGallery">
        <div
          className="section-full content-inner-1 bg-white"
          id="projects"
          style={{
            backgroundImage: 'url(./images/background/bannerButeliiTransp.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '80%',
          }}
        >
          <div className="food-project">
            <div className="content-section position-relative">
              <div className="project-carousel swiper-container">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="dz-box dz-gallery-box">
                      <div className="dz-media">
                        <a href="#">
                          <img src={gasTankBlue} alt="" />
                        </a>
                        <div className="product-info"></div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="dz-box dz-gallery-box">
                      <div className="dz-media">
                        <a href="#">
                          <img src={gasTankSingle} alt="" />
                        </a>
                        <div className="product-info"></div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="dz-box dz-gallery-box">
                      <div className="dz-media">
                        <a href="#">
                          <img src={gasTankRojevas} alt="" />
                        </a>
                        <div className="product-info"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-nav">
                <div className="project-prev">
                  <i className="las la-arrow-left"></i>
                </div>
                <div className="project-next">
                  <i className="las la-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderGallery;
