import React from 'react';
import gplMap from '../../../assets/images/Stations/gplMap.jpg';
import mixMap from '../../../assets/images/Stations/mixtMap.jpg';
import './index.css';
import BannerWithButton from '../../common/BannerWithButton';
import GplStations from './GplStations';
import MapInfoComponent from '../../common/MapInfoComponentV2/MapInfoComponent';
import MixedStations from './MixedStations';

const mixedLocations = [
  {
    name: 'STAȚIE MIXTA ROJEVAS BERESTI ',
    phoneNumber: '0753128609',
    location: { lat: 46.08, lng: 27.89 },
    address: 'Str. Trandafirilor, nr. 2B, Beresti, Galati,',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE MIXTA ROJEVAS FOLTESTI ',
    phoneNumber: '0756039843',
    location: { lat: 45.730255, lng: 28.059907 },
    address: 'SAT FOLTESTI, COM. FOLTESTI, TARLA 56/1, PARCELA 749/1',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL TARGU OCNA',
    phoneNumber: '0759991729',
    location: { lat: 46.275172, lng: 26.634111 },
    address: 'STR. COSTACHE NEGRI, NR. 148-150',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL VASLUI 3',
    phoneNumber: '0753128617',
    location: { lat: 46.64657951254662, lng: 27.710681462970935 },
    address: 'Str. Calugareni, nr. 133, Vaslui, Vaslui',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL SUTESTI',
    phoneNumber: '0749272136',
    location: { lat: 45.22, lng: 27.42 },
    address: 'Str. Ramnicu Sarat, nr. 63, Sutesti, Braila,',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL FAUREI',
    phoneNumber: '0752 280 050',
    location: { lat: 45.08, lng: 27.28 },
    address: 'Str. Republicii, nr.65B, Faurei, Braila',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL MATCA',
    phoneNumber: '0746560154',
    location: { lat: 45.84, lng: 27.48 },
    address: 'Str. Stefan cel Mare, nr. 516A, Matca, Galati,',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL BRAHASESTI',
    phoneNumber: '0749841367',
    location: { lat: 46.04, lng: 27.36 },
    address: 'Str. Principala , nr. 60A, Brahasesti, Galati,',
    type: ' Benzina, Motorina, Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE EXPRESS ROMPETROL TARGU BUJOR',
    phoneNumber: '0751515445',
    location: { lat: 45.87, lng: 27.91 },
    address: 'Str. Pietii, nr. 25, TarguBujor, Galati,',
    type: 'Benzina, Motorina, GPL , Butelii 8 Kg, Butelii 11kg',
  },
];

const gplLocations = [
  {
    name: 'STAȚIE GPL ROJEVAS GALATI 1',
    phoneNumber: '0751177724',
    location: { lat: 45.46, lng: 28.03 },
    address: 'Str. Lozoveni, nr. 233, Galati, Galati',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS GALATI 2 ',
    phoneNumber: '0758029191',
    location: { lat: 45.44, lng: 28.024 },
    address: 'Str. Clabucet, nr. 3-5, Galati, Galati',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS BARLAD 1 ',
    phoneNumber: '0751177723',
    location: { lat: 46.22, lng: 27.673 },
    address: 'Str. Ioan Popescu, nr. 33, Barlad, Vaslui',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS BARLAD 2 ',
    phoneNumber: '0759850850',
    location: { lat: 46.216, lng: 27.662 },
    address: 'Str. Republicii, nr. 13, Barlad, Vaslui',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS VASLUI 1 ',
    phoneNumber: '0751177726',
    location: { lat: 46.64, lng: 27.72 },
    address: 'Str. Castanilor, nr. 6, Vaslui, Vaslui',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS VASLUI 2 ',
    phoneNumber: '0754241792',
    location: { lat: 46.63, lng: 27.73 },
    address: 'Str. Stefan cel Mare, nr. 22, Vaslui, Vaslui',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS TECUCI ',
    phoneNumber: '0756039845',
    location: { lat: 45.83, lng: 27.43 },
    address: 'Str.  1 Decembrie 1918, nr. 109, Tecuci, Galati',
    type: 'GPL , Butelii 8 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS BACAU 1 ',
    phoneNumber: '0741241550',
    location: { lat: 46.52, lng: 26.92 },
    address: 'Str. Republicii, nr. 89C, Bacau, Bacau,',
    type: 'GPL , Butelii 9 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS BACAU 2 ',
    phoneNumber: '0757014892',
    location: { lat: 46.54, lng: 26.9 },
    address: 'Str. Alexei Tolstoi, nr. 67, Bacau, Bacau,',
    type: 'GPL , Butelii 9 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS TARGU NEAMT ',
    phoneNumber: '0751095659',
    location: { lat: 47.2, lng: 26.37 },
    address: 'Str. Ciprian Porumbescu, nr.1, Targu Neamt, Neamt,',
    type: 'GPL , Butelii 9 Kg, Butelii 11kg',
  },
  {
    name: 'STAȚIE GPL ROJEVAS BUHUSI ',
    phoneNumber: '0754241793',
    location: { lat: 46.71, lng: 26.69 },
    address: 'Str. Libertatii, nr. 36, Buhusi, Bacau,',
    type: 'GPL , Butelii 9 Kg, Butelii 11kg',
  },
];

function Stations() {
  const stationsDesktopBanner = {
    backgroundImage: 'url(images/background/stationsBanner.png)',
  };
  const stationsTabletBanner = {
    backgroundImage: 'url(images/background/stationsBannerTablet.jpg)',
  };

  return (
    <main id="stations">
      <div className="page-content bg-white">
        <div
          className="dz-bnr-inr dz-bnr-inr-md text-center overlay-black-middle backroungImage"
          style={window.innerWidth >= 988 ? stationsDesktopBanner : stationsTabletBanner}>
          <div className="container">
            <div className="dz-bnr-inr-entry">
              <h1 className="text-white stations-tittle">Stații PECO</h1>
            </div>
          </div>
        </div>
      </div>
      <MixedStations />
      <MapInfoComponent orangeBanner="true" locations={mixedLocations} img={mixMap} />
      <GplStations />
      <MapInfoComponent locations={gplLocations} img={gplMap} />
      <BannerWithButton />
    </main>
  );
}

export default Stations;
