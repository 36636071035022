import React from 'react';
import imgRompetrol from '../../../assets/images/Stations/gpl_frontside.png';
import map_marker from '../../../assets/icons/map_marker.svg';
import talk_phone from '../../../assets/icons/talk_phone.svg';
import gas_pump from '../../../assets/icons/gas-pump.svg';
import './Info.css';
function Info(props) {
  return (
    <>
      <div className="content-inner-1 contact-inner p-0 Img">
        <img
          alt=""
          className="img-cover img-fluid Road"
          src={props.img || imgRompetrol}
        />
        <div className="dz-form bg-primary style-1 information ">
          <div className="contact-inner">
            <div className="form-label one">
              <h3 className="title info-title">{props.clickedItem.name}</h3>
              <div className="style-11 ">
                <div className="d-flex icon-info-area">
                  <picture>
                    <img
                      alt="Map marker icon"
                      className="info-icon-map"
                      src={map_marker}
                    />
                  </picture>

                  <p className="mapP">{props.clickedItem.address}</p>
                </div>
                <div className="d-flex icon-info-area">
                  <picture>
                    <img
                      alt="Phone icon"
                      className="info-icon-phone"
                      src={talk_phone}
                    />
                  </picture>

                  <p className="mapP">{props.clickedItem.phoneNumber}</p>
                </div>
                {props.clickedItem.type ? (
                  <div className="d-flex icon-info-area">
                    <picture>
                      <img
                        alt="Product Type"
                        className="info-icon-product"
                        src={gas_pump}
                      />
                    </picture>

                    <p className="mapP">{props.clickedItem.type}</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
