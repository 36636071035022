import React from 'react';
import rompetrolMain from '../../../assets/images/FuelComponent/twoTrucks.png';
import logos from '../../../assets/images/FuelComponent/logos.png';
import FuelEfix from '../../common/FuelEfix';
import BannerWithButton from '../../common/BannerWithButton';
import ComboImagesWithText from '../../common/ComboImagesWithText';
import './index.css';

import greenTruck from '../../../assets/images/FuelComponent/greenTruck.jpg';
import truckRojevas from '../../../assets/images/FuelComponent/truckRojevas.jpg';
import smallTruckLeft from '../../../assets/images/FuelComponent/smallTruckLeft.jpg';
import whiteTruckRight from '../../../assets/images/FuelComponent/whiteTruckRight.jpg';
import fuelEnDetailRompetrol from '../../../assets/images/FuelComponent/fuelEnDetailRompetrol.jpg';
import fuelEnDetail from '../../../assets/images/FuelComponent/fuelEnDetail.jpg';
import fuelEnDetailRound from '../../../assets/images/FuelComponent/fuelEnDetailRound.jpg';
import fuelEnDetailTruck from '../../../assets/images/FuelComponent/fuelEnDetailTruck.jpg';

import greenTruckTablet from '../../../assets/images/FuelComponent/Tablet/greenTruckTablet.jpg';
import truckRojevasTablet from '../../../assets/images/FuelComponent/Tablet/truckRojevasTablet.jpg';
import smallTruckLeftTablet from '../../../assets/images/FuelComponent/Tablet/smallTruckLeftTablet.jpg';
import whiteTruckRightTablet from '../../../assets/images/FuelComponent/Tablet/whiteTruckRightTablet.jpg';
import fuelEnDetailTablet from '../../../assets/images/FuelComponent/Tablet/fuelEnDetailTablet.jpg';
import fuelEnDetailRompetrolTablet from '../../../assets/images/FuelComponent/Tablet/fuelEnDetailRompetrolTablet.jpg';
import fuelEnDetailRoundTablet from '../../../assets/images/FuelComponent/Tablet/fuelEnDetailRoundTablet.jpg';
import fuelEnDetailTruckTablet from '../../../assets/images/FuelComponent/Tablet/fuelEnDetailTruckTablet.jpg';

import greenTruckMobile from '../../../assets/images/FuelComponent/Mobile/greenTruckMobile.jpg';
import smallTruckLeftMobile from '../../../assets/images/FuelComponent/Mobile/smallTruckLeftMobile.jpg';
import truckRojevasMobile from '../../../assets/images/FuelComponent/Mobile/truckRojevasMobile.jpg';
import whiteTruckRightMobile from '../../../assets/images/FuelComponent/Mobile/whiteTruckRightMobile.jpg';
import fuelEnDetailMobile from '../../../assets/images/FuelComponent/Mobile/fuelEnDetailMobile.jpg';
import fuelEnDetailRompetrolMobile from '../../../assets/images/FuelComponent/Mobile/fuelEnDetailRompetrolMobile.jpg';
import fuelEnDetailRoundMobile from '../../../assets/images/FuelComponent/Mobile/fuelEnDetailRoundMobile.jpg';
import fuelEnDetailTruckMobile from '../../../assets/images/FuelComponent/Mobile/fuelEnDetailTruckMobile.jpg';
import twoTrucksTablet from '../../../assets/images/FuelComponent/twoTrucksTablet.jpg';

const imgLayoutContent1 = [
  {
    mobile: greenTruckMobile,
    tablet: greenTruckTablet,
    desktop: greenTruck,
  },
  [
    {
      mobile: truckRojevasMobile,
      tablet: truckRojevasTablet,
      desktop: truckRojevas,
    },
    {
      mobile: smallTruckLeftMobile,
      tablet: smallTruckLeftTablet,
      desktop: smallTruckLeft,
    },
  ],
  {
    mobile: whiteTruckRightMobile,
    tablet: whiteTruckRightTablet,
    desktop: whiteTruckRight,
  },
];

const imgLayoutContent2 = [
  {
    mobile: fuelEnDetailMobile,
    tablet: fuelEnDetailTablet,
    desktop: fuelEnDetail,
  },
  [
    {
      mobile: fuelEnDetailRoundMobile,
      tablet: fuelEnDetailRoundTablet,
      desktop: fuelEnDetailRound,
    },
    {
      mobile: fuelEnDetailRompetrolMobile,
      tablet: fuelEnDetailRompetrolTablet,
      desktop: fuelEnDetailRompetrol,
    },
  ],
  {
    mobile: fuelEnDetailTruckMobile,
    tablet: fuelEnDetailTruckTablet,
    desktop: fuelEnDetailTruck,
  },
];

const Fuel = () => {
  const desktopBanner = {
    backgroundImage: `url(${rompetrolMain})`,
  };
  const tabletBanner = {
    backgroundImage: `url(${twoTrucksTablet})`,
  };

  return (
    <main id="fuel">
      <div className="page-content">
        <div
          className="dz-bnr-inr dz-bnr-inr-md text-center overlay-black-middle backroungImage"
          style={window.innerWidth >= 988 ? desktopBanner : tabletBanner}
        >
          <div className="container">
            <div className="dz-bnr-inr-entry">
              <h1 className="text-white fuelText">Combustibili</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center logos-container">
        <img
          src={logos}
          className="img-fluid shadow-4 rompetrolLogos"
          alt="Responsive image"
        />
      </div>
      <ComboImagesWithText
        imgLayoutContent={imgLayoutContent1}
        key="ComboImagesWithText"
      >
        <div className="cst-normal">
          <h4 className="title same-title"> Combustibili Angro </h4>
          <div className="p-wrapper">
            <p className="m-b15">
              Cu o echipă specializată în vânzări și logistică, punem la
              dispoziția clienților consultanță pentru eficientizarea
              consumurilor mari de carburanți, dar și soluții pentru furnizarea
              acestora.
            </p>
            <p className="m-b15">
              Vânzările angro de carburant se adresează clienților din sfera
              agriculturii, transporturilor, construcțiilor sau orice alt
              domeniu și vin în întâmpinarea dumneavoastră cu propuneri și
              soluții adaptate pentru reducerea costurilor de carburant.
            </p>
            <p className="m-b15">
              Livrăm rapid și la cele mai înalte standarde de siguranță, orice
              tip de carburant (BENZINA, MOTORINA, GPL) aveți nevoie pentru
              afacerea dumneavoastră!
            </p>
            <p>
              Sortimentele de carburanți, motorină, benzină și GPL,
              comercializate în România de către ROJEVAS, sunt marca Rompetrol.
              Acestea protejează motorul, stimulează funcționarea optimă a
              acestuia, asigură un consum diminuat și reduc poluarea.
            </p>
          </div>
          <div className="icon-bx-wraper">
            <i className="fas fa-phone" />
            <div>
              <h5 className="town"> Galați </h5>
              <span>
                <a className="townPhone" href={`tel:+40 754 226 610`}>
                  +40 754 226 610
                </a>
              </span>
            </div>
          </div>
          <div className="icon-bx-wraper">
            <i className="fas fa-phone" />
            <div>
              <h5 className="town"> Brăila </h5>
              <span>
                <a className="townPhone" href={`tel:+40 756 058 898`}>
                  +40 756 058 898
                </a>
              </span>
            </div>
          </div>
        </div>
      </ComboImagesWithText>
      <FuelEfix />
      <ComboImagesWithText
        imgLayoutContent={imgLayoutContent2}
        reverseColumn={true}
        key="ComboImagesWithText-reverse"
      >
        <div className="cst-reverse">
          <h4 className="title same-title"> Combustibili En detail </h4>
          <p>
            ROJEVAS oferă clienților săi carburant de calitate, marca ROMPETROL
            EFIX. Această gamă este produsă la Petromidia, cea mai performantă
            rafinărie din sud-estul Europei și cea mai mare din România.
            Calitatea produselor marca EFIX este verificată constant, din
            punctul de extracție și până la pompă. În stațiile ROJEVAS, clienții
            găsesc și GPL Rompetrol.
          </p>
          <a href="/statii-peco" className="contact-link-btn">
            <span>Vezi stațiile PECO</span>
          </a>
        </div>
      </ComboImagesWithText>
      <BannerWithButton />
    </main>
  );
};

export default Fuel;
