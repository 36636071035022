import './MixedStations.css';
import React from 'react';
import stationsRompetrol from '../../../assets/images/Stations/stationsRompetrol.jpg';
import stationsExpress from '../../../assets/images/Stations/stationsExpress.jpg';
import stationsGasTankCut from '../../../assets/images/Stations/stationsGasTankCut.jpg';
import stationsGasTankSide from '../../../assets/images/Stations/stationsGasTankSide.jpg';
import flower_image from '../../../assets/images/Global/flower_image.png';

const MixedStations = () => {
  const [visibleRojevas, setVisibleRojevas] = React.useState(false);

  return (
    <section
      className="content-inner section-title style-2 mixed-stations-section"
      data-name="About Us">
      <div className="container">
        <div className="container picsAndText">
          <div className="row align-items-center about-bx6">
            <div className="col-lg-6 col-md-12 dz-media tetris">
              <img
                alt="station_small_image"
                src={visibleRojevas ? stationsRompetrol : stationsRompetrol}
                className="img1 aos-item aos-init aos-animate"
              />
              <div className="img2 aos-item aos-init aos-animate pics2">
                <img
                  alt="station_big_image"
                  src={visibleRojevas ? stationsExpress : stationsExpress}
                  className="tetris-big-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mixed-stations">
              {visibleRojevas ? (
                <h2 className="title">Stații MIXTE</h2>
              ) : (
                <h2 className="title">Stații MIXTE</h2>
              )}

              <div className="d-flex btn-area">
                <button
                  className={visibleRojevas ? 'stations-btn grey' : 'stations-btn'}
                  onClick={visibleRojevas ? () => setVisibleRojevas(!visibleRojevas) : null}>
                  Expres Rompetrol
                </button>
                <button
                  className={!visibleRojevas ? 'stations-btn grey' : 'stations-btn'}
                  onClick={!visibleRojevas ? () => setVisibleRojevas(!visibleRojevas) : null}>
                  Rojevas
                </button>
              </div>

              <div className="wrapper">
                <p className="m-b15">
                  Stațiile Rompetrol Express au fost concepute pentru a deveni cele mai accesibile
                  stații peco din zonele în care sunt amplasate și se adresează persoanelor fizice,
                  micilor întreprinzători agricoli și unităților economice care apreciază calitatea
                  carburantului.
                </p>
                <p className="m-b15">
                  Din aceste stații de carburant se pot achiziționa aceleași produse de calitate
                  prezente în stațiile proprii Rompetrol.
                </p>
                <p>
                  Locațiile Express Rompetrol pun la dispoziția clienților facilitățile de plată
                  Rompetrol, permițând astfel alimentarea prin intermediul cardului Fill & Go sau al
                  inelului de la Flota Rompetrol, alimentarea fiind asistată de un operator.
                </p>
              </div>

              <ul className="list-square-check grid-2">
                <li>Benzină</li>
                <li>Motorină</li>
                <li className={visibleRojevas ? 'disable' : ''}>Fill&Go</li>
                <li>GPL</li>
              </ul>
            </div>
          </div>
        </div>
        <img src={flower_image} className="flower one" alt="" />
        <img src={flower_image} className="flower two" alt="" />
      </div>
    </section>
  );
};

export default MixedStations;
