import React from 'react';
import './index.css';

import Slider from '../../../components/common/Slider';
import BannerWithButton from '../../../components/common/BannerWithButton';
import SimpleInfoBox from '../../../components/common/SimpleInfoBox';
import OffersSection from '../../common/OffersSection';
import InfoCards from '../../common/InfoCards';
import BannerWithColumns from '../../common/BannerWithColumns';

export default () => {
  const desktopBackground = {
    backgroundImage: `url(/images/background/backgroundHomePage.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'right top, left bottom',
    backgroundSize: '100%',
  };
  const tabletBackground = {
    backgroundImage: `url(/images/background/backgroundHomePage.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'bottom',
    backgroundSize: '227%',
  };

  return (
    <>
      <Slider />
      <br />
      <SimpleInfoBox />
      <br />
      <div
        style={window.innerWidth >= 988 ? desktopBackground : tabletBackground}
      >
        <OffersSection />
        <br />
        <BannerWithColumns />
        <br />
        <InfoCards />
        <br />
        <BannerWithButton />
      </div>
    </>
  );
};
