import './index.css';
import rojevasLogoWhite from '../../../assets/logos/Rojevas-White.svg';
import rojevasLogoBlue from '../../../assets/logos/Rojevas-Blue.svg';

const Header = (props) => {
  return (
    <header className="site-header mo-left header-transparent style-2 header-overlay headerWrapper">
      <div className="top-bar">
        <div className="container-fluid">
          <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
            <div className="dz-topbar-left">
              <ul>
                <li>
                  <i className="far fa-clock"></i> Program: Luni-Vineri
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i> Galați, Str.
                  Clăbucet, Nr.3-5, Jud. Galați
                </li>
              </ul>
            </div>
            <div className="dz-topbar-right">
              <ul className="dz-social">
                <li>
                  <a
                    target="_blank"
                    className="fab fa-facebook-f"
                    href={window.__APP_CONFIG__.facebookUrl}
                  ></a>
                </li>
                <li>
                  <a
                    target="_blank"
                    className="fab fa-instagram"
                    href={window.__APP_CONFIG__.instagramUrl}
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar clearfix">
          <div className="container-fluid clearfix">
            <div className="logo-header mostion">
              <a href="/" className="light-logo logo-white">
                <img src={rojevasLogoWhite} alt="" />
              </a>
              <a href="/" className="dark-logo logo-dark">
                <img src={rojevasLogoBlue} alt="" />
              </a>
            </div>

            <button
              className="navbar-toggler collapsed navicon justify-content-end"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div className="extra-nav">
              <div className="extra-cell">
                <div className="phone-call">
                  <i className="fas fa-phone-alt"></i>
                  <span>
                    <a href={`tel:+40 (336) 408 977`}>+40 (336) 408 977</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="dz-quik-search">
              <form onChange={() => {}}>
                <input
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Keyword ..."
                />
                <span id="quik-search-remove">
                  <i className="ti-close"></i>
                </span>
              </form>
            </div>
            <div
              className="header-nav navbar-collapse collapse justify-content-start nav-style-1"
              id="navbarNavDropdown"
            >
              <div className="logo-header logo-dark">
                <a href="index.html">
                  <img src="images/logo.png" alt="" />
                </a>
              </div>
              <ul className="nav navbar-nav">
                <li
                  className={`${
                    window.location.pathname === '/' ? 'active' : ''
                  }`}
                >
                  <a href="/">Acasă</a>
                </li>
                <li
                  className={`${
                    window.location.pathname === '/combustibili' ? 'active' : ''
                  }`}
                >
                  <a href="/combustibili">Combustibili</a>
                </li>
                <li
                  className={` ${
                    window.location.pathname === '/butelii' ? 'active' : ''
                  }`}
                >
                  <a href="/butelii">Butelii</a>
                </li>
                <li
                  className={` ${
                    window.location.pathname === '/statii-peco' ? 'active' : ''
                  }`}
                >
                  <a href="/statii-peco">Stații Peco</a>
                </li>
                <li
                  className={`sub-menu-down ${
                    window.location.pathname === '/inputuri-agricole' ||
                    window.location.pathname === '/crama-sorai' ||
                    window.location.pathname === '/terenuri'
                      ? 'active'
                      : ''
                  }`}
                >
                  <a
                    href={window.innerWidth > 991 ? '/inputuri-agricole' : '#'}
                  >
                    Agricultură
                  </a>
                  <ul className="sub-menu">
                    <li>
                      <a href="/inputuri-agricole">Inputuri Agricole</a>
                    </li>
                    <li>
                      <a href="/crama-sorai">Cramă Sorai</a>
                    </li>
                    <li>
                      <a href="/terenuri">Terenuri</a>
                    </li>
                  </ul>
                </li>
                <li
                  className={`${
                    window.location.pathname === '/contact' ? 'active' : ''
                  }`}
                >
                  <a href="/contact">Contact</a>
                </li>
              </ul>
              <div className="dz-social-icon">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      className="fab fa-facebook-f"
                      href={window.__APP_CONFIG__.facebookUrl}
                    ></a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      className="fab fa-instagram"
                      href={window.__APP_CONFIG__.instagramUrl}
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
