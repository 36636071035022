import './index.css';

const SimpleInfoBox = () => {
  return (
    <section className="content-inner-2 service-area-1 simpleInfoBox">
      <div className="container">
        <div className="row align-items-center section-head style-1">
          <div
            className="col-lg-6 aos-item"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400">
            <h5 className="text-primary sub-title">DESPRE NOI</h5>
            <h2 className="title simpleInfoBoxTitle">Compania Rojevas</h2>
          </div>
          <div
            className="col-lg-6 aos-item"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600">
            <p className="m-b0">
              <a href="#" className="text-primary marginNone">
                ROJEVAS
              </a>
              este o companie situată în partea central-estică a României, care oferă produse
              petroliere lichide și gazoase, precum și servicii de agronomie pe piața regională.
            </p>
            <br />
            <p>
              Cu peste 20 ani de experiență în industria petrolieră și in agronomie, oferim o gamă
              amplă de servicii, pornind de la butelii și combustibil, până la îngrășăminte și
              semințe de cea mai bună calitate.
            </p>
            <p>
              La Rojevas îi ajutăm pe clienții noștri să se ocupe în mod prioritar de afacerile lor
              și să le dezvolte. Ne străduim să creăm valoare pentru partenerii noștri în tot ceea
              ce facem, în întreg procesul de colaborare, pornind de la comunicare transparentă și
              eficientă și pana la livrarea unor produse superioare calitativ.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleInfoBox;
