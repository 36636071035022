import './index.css';

const Clients = () => {
  return (
    <div className="clients-section-1 bg-light">
      <div className="container">
        <div className="swiper-container clients-swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/adama.svg"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/adama.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/corteva.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/corteva.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img
                  className="logo-main"
                  src="images/clients/kws.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/kws.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/lebosol.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/lebosol.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/dekalb.svg"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/dekalb.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/nufarm.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/nufarm.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <img
                  className="logo-main"
                  src="images/clients/sumiagro.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/sumiagro.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <img
                  className="logo-main"
                  src="images/clients/syngenta.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/syngenta.png"
                  alt=""
                />
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="clients-logo aos-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <img
                  className="logo-main"
                  src="images/clients/timac_agro.png"
                  alt=""
                />
                <img
                  className="logo-hover"
                  src="images/clients/timac_agro.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
