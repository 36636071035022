import React from 'react';
import Routes from '../src/routes/routes';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Loader from './components/common/Loader';

function App() {
  return (
    <div className="App">
      {/*<Loader />*/}
      <div className="page-wraper">
        <Header />
        <div className="page-content bg-white">
          <Routes />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
