import React, { useState } from 'react';
import MapComponent from './MapComponent';
import Info from './Info';
import './MapInfoComponent.css';
import OrangeBanner from './OrangeBanner';

function MapInfoComponent(props) {
  const [clickedItem, setclickedItem] = useState(props.locations && props.locations[0]);
  const mobileCoordinates = {
    lat: 45.42,
    lng: 27.46,
    zoom: 7.5,
  };

  const desktopCoordinates = {
    lat: 45.42,
    lng: 27.46,
    zoom: 8.5,
  };
  return (
    <section className="bg-light mainSection">
      <div className="container-inner">
        <div className="row">
          <div className="col-lg-6 col-md-12 map-container">
            <MapComponent
              coordinates={window.innerWidth >= 769 ? desktopCoordinates : mobileCoordinates}
              onMarkerClick={(peco) => setclickedItem(peco)}
              locations={props.locations}
            />
          </div>
          <div
            className="col-lg-6 col-md-12 aos-item p-0 map-info"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600">
            <Info img={props.img} clickedItem={clickedItem} />
          </div>
        </div>
      </div>
      {props.orangeBanner && <OrangeBanner />}
    </section>
  );
}

export default MapInfoComponent;
