import React from 'react';
import './index.css';

export default () => {
  const desktopBanner = {
    backgroundImage: 'url(images/background/bannerGasTankV2.png)',
  };
  const tabletBanner = {
    backgroundImage: 'url(images/background/bannerGasTankTablet.png)',
  };

  return (
    <div>
      <div
        className="dz-bnr-inr dz-bnr-inr-sm text-center overlay-black-middle bannerWithCenterText"
        style={window.innerWidth >= 988 ? desktopBanner : tabletBanner}
      >
        <div className="container">
          <div className="dz-bnr-inr-entry">
            <h1 className="text-white">Butelii</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
