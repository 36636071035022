import React from 'react';
import './index.css';

const TextWithTitle = () => {
  return (
    <>
      <section className="content-inner-2 service-area-1 textWithTitle">
        <div className="container">
          <div className="row align-items-center section-head style-1">
            <div
              className="col-lg-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400">
              <h5 className="text-primary sub-title">Butelii</h5>
              <h2 className="title">Livrari la domiciliu</h2>
            </div>
            <div
              className="col-lg-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600">
              <p className="m-b2">
                Beneficiază de calitatea produselor Rojevas din confortul casei tale! Cu ajutorul
                echipei noastre de specialiști și o logistică dezvoltată cu atenție, livrăm butelii
                la domiciliul dumneavoastră în următoarele orașe: Galaţi,Bârlad, Târgu Ocna(şi
                împrejurimi), Buhuşi(şi împrejurimi), Bacău (şi împrejurimi), Târgu Neamţ (şi
                împrejurimi).
              </p>
              <p>Siguranța ta este importantă pentru noi, așa că venim noi la tine!</p>
              <p className="transform">Vezi harta zonelor cu livrare</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TextWithTitle;
