import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from '../components/pages/HomePage';
import GasTank from '../components/pages/GasTank';
import Fuel from '../components/pages/Fuel';
import AgriculturalInputs from '../components/pages/Agriculture/AgriculturalInputs';
import Lands from '../components/pages/Agriculture/Lands';
import WineCellar from '../components/pages/Agriculture/WineCellar';
import Stations from '../components/pages/Stations';
import Contact from '../components/pages/Contact';
import TermsAndConditions from '../components/pages/TermsAndConditions/index';

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/combustibili" exact component={Fuel} />
        <Route path="/butelii" exact component={GasTank} />
        <Route path="/statii-peco" exact component={Stations} />
        <Route path="/inputuri-agricole" exact component={AgriculturalInputs} />
        <Route path="/terenuri" exact component={Lands} />
        <Route path="/crama-sorai" exact component={WineCellar} />
        <Route path="/contact" exact component={Contact} />
        <Route
          path="/termeni-si-conditii"
          exact
          component={TermsAndConditions}
        />
      </Switch>
    </Router>
  );
};
