import './index.css';

const BannerWithButton = () => {
  return (
    <div
      className="section-full call-action bg-primary wow fadeIn bannerWithButton"
      data-wow-duration="2s"
      data-wow-delay="0.2s"
      style={{ backgroundImage: 'url(/images/main-slider/slide3.jpg)' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 text-white text-lg-start text-center mb-lg-0 mb-4">
            <h2 className="title text-white">Rojevas, un partener de nădejde.</h2>
            <p className="mb-0">
              De peste 20 ani pe piață regională Rojevas aduce calitate clienților săi.
            </p>
          </div>
          <div className="col-lg-4 text-lg-end text-center">
            <a href="/contact" className="btn btn-secondary primaryButton">
              <span>CONTACTEAZĂ-NE</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWithButton;
