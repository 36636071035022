import React from 'react';
import './index.css';

const TextWithAnimation = () => {
  return (
    <>
      <section className="content-inner-2 textWithAnimation">
        <div className="container">
          <div className="row align-items-center about-bx5 width">
            <div className="col-lg-6">
              <div className="dz-media">
                <img
                  src="images/backgroundGasTankTransp.png"
                  alt=""
                  className="img1 aos-item aos-init aos-animate"
                  data-aos="fade-down"
                  data-aos-duration="800"
                  data-aos-delay="400"
                />
                <img
                  src="images/rompetrol_large_logo_transparent.png"
                  className="circle-bg"
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 m-b30 aos-item aos-init aos-animate marginTop"
              data-aos="fade-in"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <div className="section-head style-3">
                <h2 className="title">Butelii aragaz</h2>
              </div>
              <div className="section-paragraph">
                <p>
                  GPL-ul din butelia de aragaz este un amestec de butan și
                  propan, care prin ardere oferă instant căldură controlabilă.
                  Este un produs verificat și certificat.
                </p>
                <p>
                  Rojevas oferă produse GPL pentru clienții casnici și clienții
                  industriali, punând accent pe satisfacerea nevoilor acestora.
                </p>
                <p>
                  Buteliile GPL Rojevas se folosesc pentru uz casnic la
                  încălzire și gătit, iar pentru uz industrial, la transporturi
                  (mașini și unelte care funcționează cu GPL) și la încălzire
                  (umbrele radiante pentru terase) sau în activități de
                  construcție. Buteliile de aragaz Rojevas se regăsesc în toate
                  stațiile PECO Rojevas și în 3 depozite regionale din țară.
                </p>
                <p>
                  Poți deveni partenerul nostru în comerțul cu butelii, Rojevas
                  având peste 3000 de parteneri cu puncte de desfacere în toată
                  regiunea Moldovei.
                </p>
              </div>
              <h4 className="title-list">Caracteristici:</h4>
              <div className="history-row m-b30">
                <ul className="list-square-check grid-4 squareCheck">
                  <li> Recipient metalic cu sau fără gardă.</li>
                  <li>
                    Cantitate 8 KG, 9 KG sau 11 KG cu Gaz Petrolier Lichefiat -
                    BUTAN
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TextWithAnimation;
