import './index.css';
import React from 'react';

const TextBannerWithVideo = () => {
  return (
    <div className="textBannerWithVideo container-{breakpoint}">
      <img className="wheyCut" src="/images/background/Layer 2.png" />
      <img className="fields" src="/images/background/fields.png" />
      <img
        className="bannerMobile"
        src="/images/background/inputuriAgricoleMobil.png"
      />
      <img
        className="bannerTablet"
        src="/images/background/inputuriAgricoleTableta.png"
      />
      <div className="simpleText">
        <br />
        <p>
          ROJEVAS este un companie puternică, ce își desfășoară activitatea în
          zona de Sud-Est a României. Societatea cu capital privat având mai
          multe domenii de activitate, intră pe piața de vânzări inputuri în
          anul 2007 când s-au stabilit și primele parteneriate cu DuPONT și
          Pioneer. Sub deviza “Calitate la prețul corect”, numărul clienților a
          crescut anual, sporind productivitatea la nivel de agribusiness.
          Astăzi colaborăm cu majoritatea companiilor producătoare de semințe,
          pesticide și îngrășăminte chimice, și asigurăm soluții complexe și
          complete pentru clienții noștri.
        </p>
        <p>
          Oferim un portofoliu complex de produse pentru îngrijirea culturilor,
          înglobând cele mai noi tehnologii. Acesta cuprinde tratamentele
          specifice tuturor culturilor, de la tratamentul seminței până la
          combaterea bolilor, dăunătorilor și buruienilor. Societatea noastră
          comercializează o gamă largă de cereale și oleaginoase: grâu, soia,
          rapiță, porumb, orz, floarea soarelui, mazăre, dar și erbicide,
          fungicide, insecticide și îngrășăminte și biostimulatori.
        </p>
        <p>
          În regiunea de Sud-Est, ROJEVAS a dezvoltat colaborări cu branduri
          precum Corteva, Nufarm, Monsanto, KWS, SUMMIT AGRO, ADAMA, TIMAC AGRO,
          Syngenta și LEBOSOL.
        </p>
        <p className="transform">
          Obținerea unor producții mari și de calitate reprezintă scopul
          oricărui fermier, iar compania noastră le propune fermierilor soluții
          moderne de agribusiness pentru atingerea acestui scop. Cu o logistică
          bine pusă la punct, livrăm produsele solicitate personal fermierilor
          și suntem mereu alături de aceștia pentru a colaborare perfectă.
          Soluțiile personalizate pe care le oferim, corespund nevoilor și
          cerințelor fermierilor, și reprezintă motorul acțiunilor noastre,
          pentru că știm că fermierul este cea mai importantă verigă a lanțului
          către un viitor mai sănătos.
        </p>
        <video
          className="wheyVideo"
          width="400"
          height="400"
          loop
          autoPlay
          muted
        >
          <source src="/images/background/wheyVideo.webm" type="video/webm" />
        </video>
        <p className="simpleTextSingle">Ia legătura cu specialiștii noștri</p>
        <div className="icon-bx-wraper margin">
          <i className="fas fa-phone" />
          <div>
            <h5 className="town">Telefon</h5>
            <span>
              <a className="townPhone" href={`tel:+40 741 241 556`}>
                +40 741 241 556
              </a>
            </span>
          </div>
        </div>
        <div className="icon-bx-wraper margin">
          <i className="fas fa-phone" />
          <div>
            <h5 className="town">Telefon</h5>
            <span>
              <a className="townPhone" href={`tel:+40 753 111 190`}>
                +40 753 111 190
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextBannerWithVideo;
