import './index.css';
import orangeLeaf from '../../../assets/images/HomePage/orange-leaf.svg';
import orangeDrop from '../../../assets/images/HomePage/orange-drop.svg';
import orangeDeposit from '../../../assets/images/HomePage/orange-deposit.svg';

const InfoCards = () => {
  return (
    <section className="infoCardsWrapper">
      <div className="row align-items-center section-head style-1">
        <div
          className="col-lg-6 aos-item infoCards"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <h5 className="text-primary sub-title">Serviciile noastre</h5>
        </div>
      </div>

      <div className="row align-items-center infoCards">
        <div
          className="col-lg-4 infoCardsWidth col-md-6 m-lg-b30 aos-item"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
          onClick={() => {
            window.location.pathname = '/inputuri-agricole';
          }}
        >
          <div className="icon-bx-wraper style-6 box-hover agricultureHover active">
            <div className="icon-lg infoCardsHover">
              <span className="iconLayout icon-cell">
                <img className="onHover" src={orangeLeaf} />
              </span>
            </div>
            <div className="icon-content">
              <h4 className="dz-title">
                SERVICII SI PRODUSE
                <br />
                PENTRU AGRONOMIE
              </h4>
              <p>
                Îngrășăminte și semințe selecționate cu grijă de specialiștii
                Rojevas
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 infoCardsWidth col-md-6 m-lg-b30 aos-item"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
          onClick={() => {
            window.location.pathname = '/combustibili';
          }}
        >
          <div className="icon-bx-wraper style-6 box-hover whiteTruckHover">
            <div className="icon-lg">
              <span className="icon-cell">
                <img className="onHover" src={orangeDrop} />
              </span>
            </div>
            <div className="icon-content">
              <h4 className="dz-title">
                SERVICII ȘI PRODUSE
                <br />
                PETROLIERE
              </h4>
              <p>
                Servicii și produse petroliere de calitate superioară.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 infoCardsWidth col-md-6 m-lg-b30 aos-item"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="800"
          onClick={() => {
            window.location.pathname = '/contact';
          }}
        >
          <div className="icon-bx-wraper style-6 box-hover contactHover">
            <div className="icon-lg">
              <span className="icon-cell">
                <img className="onHover" src={orangeDeposit} />
              </span>
            </div>
            <div className="icon-content">
              <h4 className="dz-title">
                DEPOZITE
                <br />
                <br />
              </h4>
              <p>IANCA , BACĂU, TÂRGU BUJOR</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoCards;
