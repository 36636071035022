import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import snazzyMapStyles from './snazzyMapStyles';
import pin from '../../../assets/icons/pin50.svg';

const mapStyles = {
  width: '50%',
  height: '100%',
};

const coordinates = {
  lat: 46.62,
  lng: 26.07,
  zoom: 8,
};

const MapComponent = (props) => {
  const locations = props.locations;
  const style = props.mapClasses ? {} : props.mapStyles || mapStyles;
  return (
    <Map
      className={props.mapClasses}
      google={props.google}
      zoom={props.coordinates?.zoom || coordinates.zoom}
      style={style}
      initialCenter={props.coordinates || coordinates}
      styles={snazzyMapStyles}
    >
      {locations &&
        locations.map((loc, idx) => (
          <Marker
            key={`${loc.location.lat}.${idx}`}
            position={loc.location}
            onClick={() => props.onMarkerClick(loc)}
            icon={pin}
          />
        ))}
    </Map>
  );
};
export default GoogleApiWrapper((props) => ({
  apiKey: window.__APP_CONFIG__.gmapsApiKey,
  language: 'RO',
  ...props,
}))(MapComponent);
