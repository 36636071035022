import flower_image from '../../../assets/images/Global/flower_image.png';
import React from 'react';
import './index.css';
import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ComboImagesWithText = (props) => {
  const { width } = useWindowDimensions();
  const { imgLayoutContent } = props;
  return (
    <div className="container cst">
      <div className="row">
        <div
          className={
            props.reverseColumn
              ? 'col-lg-7 col-md-6 reverseColumn comboOrange'
              : 'col-lg-7 col-md-6 comboOrange'
          }
        >
          {imgLayoutContent.map((block, idx) => {
            // a block represents a picture block with sources for the different devices
            // associate object with a full width block
            if (Object.prototype.toString.call(block) === '[object Object]') {
              return (
                <picture key={`${idx}-block-single-picture`}>
                  <source media="(min-width:992px)" srcSet={block.desktop} />
                  <source media="(min-width:768px)" srcSet={block.tablet} />
                  <img
                    src={block.mobile}
                    alt="rompetrol"
                    className=" img-fluid rounded-lg first"
                  />
                </picture>
              );
            }
            // associate an array with a row of multiple blocks
            if (Array.isArray(block)) {
              const lengthOfBlock = block.length;
              const moduloOfBlock = 12 % lengthOfBlock;
              if (!moduloOfBlock) {
                const numOfCols = 12 / lengthOfBlock;
                return (
                  <div className="row" key={`${idx}-block-row`}>
                    {block.map((picture, idx) => {
                      return (
                        <div
                          className={`col-lg-${numOfCols} col-md-12 cst middle-area`}
                          key={`${idx}-block-row-picture`}
                        >
                          <picture>
                            <source
                              media="(min-width:992px)"
                              srcSet={picture.desktop}
                            />
                            <source
                              media="(min-width:768px)"
                              srcSet={picture.tablet}
                            />
                            <img
                              alt=""
                              src={picture.mobile}
                              className=" img-fluid rounded-lg middle"
                            />
                          </picture>
                        </div>
                      );
                    })}
                  </div>
                );
              } else {
                console.error('Please use multiple of 12 for layout purposes.');
              }
            }
          })}
        </div>
        <div className="col-lg-5 col-md-6">{props.children}</div>
      </div>

      {width > 991 ? (
        !props.reverseColumn ? (
          <>
            <img src={flower_image} className="circle-bg-one" alt="Flower" />
            <img src={flower_image} className="circle-bg-two" alt="Flower" />
          </>
        ) : (
          <img src={flower_image} className="circle-bg-three" alt="Flower" />
        )
      ) : null}
    </div>
  );
};

export default ComboImagesWithText;
