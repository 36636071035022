import React, { useState } from 'react';
import './index.css';
import rojevasLogoWhite from '../../../assets/logos/Rojevas-White.svg';
import banner from '../../../assets/images/HomePage/footerBanner.jpg';
import bannerTablet from '../../../assets/images/HomePage/bannerTablet.jpg';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import joi from 'joi';

const Footer = () => {
  let subscribeSchema = joi
    .string()
    .email({ tlds: { allow: false } })
    .required();

  const subInfoInit = {
    email: '',
    errored: false,
    sent: false,
  };

  const [subInfoState, setSubInfoState] = useState(subInfoInit);

  const sendEmailToCampaign = (event) => {
    event.preventDefault();
    const _subInfoState = { ...subInfoState };

    // validate
    const validated = subscribeSchema.validate(subInfoState.email);

    if (validated.error?.details.length) {
      setSubInfoState({ ..._subInfoState, errored: true, sent: false });
      return;
    }

    axios
      .post(`${window.__APP_CONFIG__.apiUrl}/contact/keep`, _subInfoState)
      .then(function (response) {
        if (response.data.ok) {
          toast('V-ati abonat cu succces.');
          setSubInfoState({
            ...subInfoInit,
            sent: true,
          });
        } else if (response.data.code === 2002) {
          toast.error(response.data.message);
        } else {
          toast.error('Eroare de trimitere.');
        }
      })
      .catch(function (error) {
        console.error(
          '### Error while trying to send subscribe info data to server : ',
          error,
          error.response?.status
        );
        if (error.response?.status == 429) {
          toast.error(
            'Ati facut prea multe cereri de acest fel. Va rugam asteptati.'
          );
          return;
        }
        toast.error('Eroare la trimitere.');
      });
  };

  const desktopBanner = {
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  };
  const tabletBanner = {
    backgroundImage: `url(${bannerTablet})`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  };

  return (
    <footer
      className="site-footer style-3 overlay-black-dark footerOverlay footerWrapper"
      id="footer"
      style={window.innerWidth >= 988 ? desktopBanner : tabletBanner}
    >
      <Toaster position="top-right" reverseOrder={false} />

      <div className="footer-top">
        <div className="container">
          <div className="row footer-logo-head spno">
            <div
              className="col-xl-6 col-lg-4 col-sm-12 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="footer-logo logo-white rojevasLogo">
                <img src={rojevasLogoWhite} alt="" />
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-4 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <div className="icon-bx-wraper style-13 m-b15">
                <div className="icon-bx-sm text-primary m-r15 border radius icon-bx">
                  <i className="fas fa-phone-alt"></i>
                </div>
                <div className="icon-content">
                  <h4 className="title m-b5">Telefon</h4>
                  <p>+40 (336) 408 977</p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-4 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              <div className="icon-bx-wraper style-13 m-b15">
                <div className="icon-bx-sm text-primary m-r15 border radius icon-bx">
                  <i className="fas fa-envelope-open-text"></i>
                </div>
                <div className="icon-content">
                  <h4 className="title m-b5">Adresa Email</h4>
                  <p>contact@rojevas.ro</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-lg-3 col-md-6 col-sm-12 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <div className="widget widget_about">
                <div className="widget-title">
                  <h4 className="title">Despre noi</h4>
                </div>
                <p>
                  ROJEVAS este o companie românească care oferă servicii și
                  produse petroliere lichide, gazoase dar și servicii și produse
                  de agronomie pe piața regională din Romania.
                </p>
                <ul className="social-list">
                  <li>
                    <a
                      target="_blank"
                      href={window.__APP_CONFIG__.facebookUrl}
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={window.__APP_CONFIG__.instagramUrl}
                      className="btn btn-primary instagramIconFooter"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="800"
            >
              <div className="widget widget_categories">
                <div className="widget-title">
                  <h4 className="title">Servicii</h4>
                </div>
                <ul>
                  <li className="cat-item">
                    <a href="/">Acasă</a>
                  </li>
                  <li className="cat-item">
                    <a href="/combustibili">Combustibili</a>
                  </li>
                  <li className="cat-item">
                    <a href="/butelii">Butelii</a>
                  </li>
                  <li className="cat-item">
                    <a href="/statii-peco">Stații PECO</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
            >
              <div className="widget">
                <div className="widget-title">
                  <h4 className="title">Despre</h4>
                </div>
                <p className="text m-b20">Program : Luni - Vineri</p>
                <p className="text m-b0">Adresa: Galați</p>
                <p className="text m-b0">Str. Clăbucet, Nr.3-5,</p>
                <p className="text m-b0">Jud. Galați</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-12 aos-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="1000"
            >
              <div className="widget">
                <div className="widget-title">
                  <h4 className="title">Abonează-te</h4>
                </div>
                <p className="text m-b20"></p>
                <div className="ft-subscribe">
                  <form className="dzSubscribe">
                    <div className="dzSubscribeMsg"></div>

                    {subInfoState.errored ? (
                      <span style={{ color: '#D52C2B', fontSize: '13px' }}>
                        Vă rugăm să introduceți un e-mail valid!
                      </span>
                    ) : (
                      ''
                    )}
                    {subInfoState.sent ? (
                      <span style={{ color: '#119203', fontSize: '13px' }}>
                        Te-ai abonat cu succes !
                      </span>
                    ) : (
                      ''
                    )}
                    <div className="input-group">
                      <input
                        name="dzEmail"
                        required="required"
                        type="email"
                        className="form-control"
                        placeholder="Adresă Email..."
                        value={subInfoState.email}
                        onChange={(event) => {
                          event.preventDefault();
                          setSubInfoState({ email: event.currentTarget.value });
                        }}
                      />
                      <button
                        name="submit"
                        value="Submit"
                        className="btn btn-primary"
                        onClick={sendEmailToCampaign}
                      >
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center fb-inner spno">
            <div className="col-lg-6 col-md-12 text-center text-lg-start">
              <span className="copyright-text">
                2021 ©
                <a href="#" className="text-primary">
                  Rojevas
                </a>
                All rights reserved.
              </span>
            </div>
            <div className="col-lg-6 col-md-12 text-center text-lg-end mt-lg-0 mt-2">
              <ul className="footer-link d-inline-block">
                <li>
                  <a href="/termeni-si-conditii">Termeni și condiții</a>
                </li>
                <li>
                  <a href="https://anpc.ro/">ANPC</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
