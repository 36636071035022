import './index.css';

const SimpleBanner = ({ image, title }) => {
  return (
    <div
      className="dz-bnr-inr dz-bnr-inr-sm text-center overlay-black-middle simpleBanner"
      style={{
        backgroundImage: `url(${image || 'images/background/spice_bag.png'})`,
      }}
    >
      <div className="container titleAlign">
        <div className="dz-bnr-inr-entry">
          <h1 className="text-white">{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default SimpleBanner;
