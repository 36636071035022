import './index.css';

const MasonryImages = ({ images }) => {
  const imgGroups = images || {
    'col-xl-6 col-md-6 col-sm-6 m-b30': [
      'images/agricultureInp.jpg',
      'images/agricultureInpProd.jpg',
    ],
    'col-xl-6 col-md-6 col-sm-6 m-b30 s': [
      'images/agricultureInpHeight.jpg',
      'images/agricultureInpProdHeight.jpg',
    ],
  };

  let groupedListItems = [];

  for (let key in imgGroups) {
    const groupedLi = imgGroups[key].map((it, idx) => {
      return (
        <li className={`card-container ${key}`} key={`${key}-${idx}`}>
          <div className="dz-box overlay style-3">
            <div
              className="dz-media mheight-lg"
              style={{
                backgroundImage: `url('${it}')`,
              }}
            >
              <span data-exthumbimage={it} data-src={it}></span>
            </div>
          </div>
        </li>
      );
    });
    groupedListItems = groupedListItems.concat(groupedLi);
  }

  return (
    <section className="content-inner masonryImages">
      <div className="container-fluid">
        <ul
          className="row lightgallery aos-item"
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          {groupedListItems}
        </ul>
      </div>
    </section>
  );
};

export default MasonryImages;
