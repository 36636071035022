import React from 'react';

import MasonryImages from '../../../common/MasonryImages';
import SimpleBanner from '../../../common/SimpleBanner';
import BannerWithPhotosAndText from '../../../common/BannerWithPhotosAndText';

const WineCellar = () => {
  const images = {
    'col-xl-6 col-md-6 col-sm-6 m-b30': [
      'images/grapeVineSunset.jpg',
      'images/grapeVine.jpg',
    ],
    'col-xl-6 col-md-6 col-sm-6 m-b30 s': [
      'images/wineCellarGrape.jpg',
      'images/wineCellarGlass.jpg',
    ],
  };

  return (
    <>
      <SimpleBanner
        image="/images/background/wine_cellar.png"
        title="CRAMĂ SORAI"
      />
      <BannerWithPhotosAndText />
      <MasonryImages images={images} />
    </>
  );
};

export default WineCellar;
