import React from 'react';
import iconGas from '../../../assets/icons/tankIcon.svg';
import './OrangeBanner.css';

function OrangeBanner() {
  return (
    <>
      <div className="orange-banner counter-area1 half-bg col orange">
        <div className="container">
          <div className="counter-inner bg-primary orange2">
            <div className="row">
              <div
                className="col-lg-3 col-sm-4 aos-item fuel-card"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200">
                <div className="icon-bx-wraper style-12" data-name="789">
                  <img alt="Gas icon" className="icon-gz" src={iconGas} />
                  <div className="d-flex flex-column nrWrapper">
                    <h2 className="counter number-wr">9</h2>
                    <h6 className="title icon-title-text">Motorină</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-sm-4 aos-item fuel-card"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400">
                <div className="icon-bx-wraper style-12" data-name="158">
                  <img alt="Gas icon" className="icon-gz" src={iconGas} />
                  <div className="d-flex flex-column nrWrapper">
                    <h2 className="counter number-wr">20</h2>
                    <h6 className="title icon-title-text">GPL</h6>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-sm-4 aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="800">
                <div className="icon-bx-wraper style-12" data-name="987">
                  <img alt="Gas icon" className="icon-gz" src={iconGas} />
                  <div className="d-flex nrWrapper">
                    <h2 className="counter number-wr">9</h2>
                    <h6 className="title icon-title-text">Benzină</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrangeBanner;
