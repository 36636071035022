import React from 'react';

import MasonryImages from '../../../common/MasonryImages';
import Clients from '../../../common/Clients';
import SimpleBanner from '../../../common/SimpleBanner';
import TextBannerWithVideo from '../../../common/TextBannerWithVideo';

const AgricultureInputs = () => {
  return (
    <>
      <SimpleBanner
        image="images/background/agricultureInputsBanner.png"
        title="INPUTURI AGRICOLE"
      />
      <TextBannerWithVideo />
      <MasonryImages />
      <Clients />
    </>
  );
};

export default AgricultureInputs;
