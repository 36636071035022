import banner from '../../../assets/images/HomePage/homeSliderTruck.jpg';
import homeSliderAgriculture from '../../../assets/images/HomePage/homeSliderAgriculture.jpg';
import gasTanksSlider from '../../../assets/images/HomePage/gasTanksSlider.jpg';

import './index.css';

const Slider = () => {
  return (
    <div className="slider-3 sliderWrapper">
      <div className="swiper-container main-silder-swiper silder-three">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div
              className="dz-slide-item overlay-black-middle"
              style={{ backgroundImage: `url(${banner})` }}>
              <div className="container">
                <div className="silder-content whiteInnerContent" data-swiper-parallax="-40%">
                  <div className="inner-content">
                    <h2 className="title">Calitate</h2>
                    <h2 className="title">la prețul corect</h2>
                    <h5 className="sub-title text-primary subtitleSlider">
                      De peste 20 ani pe piața regională, Rojevas aduce calitate clienților săi.
                    </h5>
                    <a href="/butelii" className="btn shadow-primary btn-primary whiteButton">
                      Află mai multe
                    </a>
                    <a href="/contact" className="btn shadow-primary btn-primary orangeButton">
                      Contactează-ne
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div
              className="dz-slide-item overlay-black-middle"
              style={{ backgroundImage: `url(${homeSliderAgriculture})` }}>
              <div className="container">
                <div className="silder-content whiteInnerContent" data-swiper-parallax="-40%">
                  <div className="inner-content">
                    <h2 className="title">Calitate</h2>
                    <h2 className="title">la prețul corect</h2>
                    <h5 className="sub-title text-primary subtitleSlider">
                      De peste 20 ani pe piața regională, Rojevas aduce calitate clienților săi.
                    </h5>
                    <a href="/butelii" className="btn shadow-primary btn-primary whiteButton">
                      Află mai multe
                    </a>
                    <a href="/contact" className="btn shadow-primary btn-primary orangeButton">
                      Contactează-ne
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div
              className="dz-slide-item overlay-black-middle"
              style={{ backgroundImage: `url(${gasTanksSlider})` }}>
              <div className="container">
                <div className="silder-content whiteInnerContent" data-swiper-parallax="-40%">
                  <div className="inner-content">
                    <h2 className="title">Calitate</h2>
                    <h2 className="title">la prețul corect</h2>
                    <h5 className="sub-title text-primary subtitleSlider">
                      De peste 20 ani pe piața regională, Rojevas aduce calitate clienților săi.
                    </h5>
                    <a href="/butelii" className="btn shadow-primary btn-primary whiteButton">
                      Află mai multe
                    </a>
                    <a href="/contact" className="btn shadow-primary btn-primary orangeButton">
                      Contactează-ne
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
