import './index.css';

const SimpleTextBanner = () => {
  return (
    <div className=" bannerWithPhotosAndText container-{breakpoint}">
      <img className="barrel" src="/images/background/barrel.png" />
      <img
        className="grapeBannerVertical"
        src="/images/background/cramaMobil.png"
      />
      <img className="grapeTablet" src="/images/background/grapeTablet.png" />
      <div className="wineCellarSimpleText">
        <p>
          Din viile noastre către fericirea dumneavoastră, împărțind magia unui
          vin revoluționar ce reușește să îmbine contemporanul cu autohtonul!
          Însă nu putem să vorbim despre creație fără să vorbim despre creatori
          și locul în care aceștia creează.
        </p>
        <p className="transform">
          Vă prezentăm soiurile CHARDONNAY FUME, OTTONEL, FETEASCĂ NEAGRĂ,
          ITALIAN, RIESLING și CABERNET SAUVIGNON.
        </p>
        <p>
          Zona de procesare a strugurilor a fost modernizată în așa fel încât
          acțiunea umană asupra vinului să fie minimă. Solul fertil și
          microclima favorabilă împreună cu tradiția milenară oferă soiurilor
          nobile și meșteșugului culturii un vin de calitate superioară: Sorai.
        </p>
        <p>
          Podgoriile Cramei Sorai sunt situate astfel încât să absoarbă toată
          lumina pe care natura o are de oferit ținutului privilegiat din
          Dealurile Bujorului.
        </p>
        <p>
          Vinul poartă atât la nivel concret, cât și la nivel simbolic,
          caracterul de esență al plantei. De aceea, în mai multe culturi, a
          fost și este considerat băutura vieții sau a nemuririi.
        </p>

        <img className="grape" src="/images/background/grape.png" />
        <a
          href="https://cramasorai.ro/"
          target="_blank"
          className="btn shadow-primary btn-primary"
        >
          <span className="buttonTextWineCellar">Vezi Cramă</span>
        </a>
      </div>
    </div>
  );
};

export default SimpleTextBanner;
