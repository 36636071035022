import './index.css';

const TermsAndConditions = () => {
  return (
    <section className="content-inner-2 service-area-1 termsAndConditions">
      <div className="container">
        <div className="row align-items-center section-head style-1">
          <div
            className="col-lg-6 aos-item"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            <h5 className="text-primary sub-title">Informatii</h5>
            <h2 className="title">Termeni și condiții</h2>
          </div>
          <div
            className="col-lg-6 aos-item"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <p>
              <strong> 1. Condiții generale de utilizare </strong>
            </p>
            <p>
              Utilizarea site-ului www.rojevas.ro (denumit în continuare “site”)
              presupune acceptarea în totalitate a termenilor și condițiilor
              expuse mai jos. Neacceptarea acestor termeni și condiții atrage
              obligația persoanei respective de a înceta accesarea site-ului.
            </p>
            <p>
              <strong>
                2. Prelucrarea datelor cu caracter personal/Folosirea
                Informatiilor
              </strong>
            </p>
            <p>
              Ori de câte ori cineva vizitează acest site, serverele sunt
              capabile să colecteze anumite date ce ne permit să urmărim numărul
              de vizite pe site. Aceste informații sunt folosite cu scopul de a
              continua procesul de îmbunătățire și dezvoltare a acestuia.
              Astfel, datele trimise către serverele noastre nu includ date
              personale care ar permite urmărirea și localizarea vizitatorilor
              individuali. Prin date personale înțelegem informații care ar
              putea trimite în mod specific la o persoană sau entitate – e-mail,
              nume sau adresă de email.
            </p>
            <p>
              Conform legii nr. 677/2001 pentru protecția persoanelor cu privire
              la prelucrarea datelor cu caracter personal și liberă circulație a
              acestor date, modificată și completată, precum și conform legii
              nr. 506/2004 privind prelucrarea datelor cu caracter personal și
              protecția vieții private în sectorul comunicațiilor electronice,
              www.rojevas.ro va administra datele furnizate de utilizatori numai
              în condiții de siguranță și numai în scopurile specificate.Datele
              personale furnizate de utilizatori sunt prelucrate în scopul
              oferirii în condiții optime de către www.rojevas.ro a serviciilor
              de reclamă, marketing și publicitate și a serviciilor de
              statistică.
            </p>
            <p>
              Datele personale sunt furnizate de utilizatori în momentul
              completarii formularului de contact de pe site-ul www.rojevas.ro .
            </p>
            <p>
              Datele colectate de la utilizatori se referă la: nume, prenume,
              număr de telefon, adresa de e-mail. Conform legii 677/2001,
              utilizatorul își poate exercită următoarele drepturi:
            </p>
            <p>
              a) după caz, rectificarea, actualizarea, blocarea sau ștergerea
              datelor a căror prelucrare nu este conformă prezenței legi, în
              special a datelor incomplete sau inexacte;
            </p>
            <p>
              b) după caz, transformarea în date anonime a datelor a căror
              prelucrare nu este conformă prezenței legi;
            </p>
            <p>
              c) dreptul de a va adresa instanței de judecată în legătură cu
              orice încălcare a drepturilor dumneavoastră cu privire la
              prelucrarea Datelor cu Caracter Personal.
            </p>
            <p>
              Drepturile la care se face referire în alineatele a-c de mai sus
              pot fi exercitate prin intermediul unei cereri scrise adresate
              companiei noastre: Str. Clabucet, nr. 3-5, Galati, cu specificația
              „În atenția www.rojevas.ro” sau prin e-mail la adresa
              contact@rojevas.ro .
            </p>
            <p>
              <strong>
                3. Materialele și informațiile accesibile prin intermediul
                site-ului www.rojevas.ro
              </strong>
            </p>
            <p>
              Conținutul , design-ul site-ului și baza de date colectată de la
              utilizatori reprezintă proprietatea www.rojevas.ro și sunt
              protejate de legislația în vigoare în România, în privința
              drepturilor de autor și a drepturilor conexe.
            </p>
            <p>
              Conținutul site-ului poate fi utilizat exclusiv în scop personal.
            </p>
            <p>
              Orice utilizare în alte scopuri decât personale se va face doar cu
              acordul scris în prealabil al www.rojevas.ro .
            </p>
            <p>
              Utilizatorii care expediază informații către site-ul
              www.rojevas.ro își asumă obligația de a nu încalcă în nici un mod
              drepturile de autor pe care o terță persoană le-ar deține și
              acceptă implicit că răspunderea pentru încălcarea acestora le
              revine în totalitate.
            </p>
            <p>
              <strong>4. Modificarea termenilor și a condițiilor</strong>
            </p>
            <p>
              www.rojevas.ro își rezervă dreptul de a modifică în orice moment
              conținutul termenilor și condițiilor de utilizare, fără o
              notificare anterioară a utilizatorului.
            </p>
            <p>
              <strong>5. Acuratețea informațiilor</strong>
            </p>
            <p className="terms">
              Rojevas are entități diferite, dar în site-ul nostru folosim
              termenii de ”Noi”, în momentul în care ne referim la companiile
              din cadrul Rojevas, în general, sau în cazul în care nu este
              nevoie de a identifica în mod specific un anumit membru al
              grupului. Rojevas face toate eforturile posibile pentru a oferi în
              acest site informații cât mai exacte, dar nu poate garanta
              acuratețea, exhaustivitatea și actualitatea tuturor informațiilor.
              Prin urmare, orice vizitator ce folosește informațiile conținute
              în acest site, face acest lucru pe propriul risc.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
