import React from 'react';
import dieselEuro5 from '../../../assets/images/FuelComponent/dieselEuro5.png';
import efix95 from '../../../assets/images/FuelComponent/efix_95_131.png';
import efix51 from '../../../assets/images/FuelComponent/efix_51_129.png';
import './index.css';

const FuelEfix = () => {
  return (
    <>
      <section className="fuel-efix">
        <div className="container">
          <div className="section-head text-center">
            <h5 className="sub-title text-primary marcaRompetrol">
              MARCA ROMPETROL
            </h5>
            <h2 className="title">Produse Efix</h2>
          </div>
        </div>
        <div className="swiper-container swiper-portfolio1">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div
                className="dz-box overlay  overlay-shine"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div className="dz-media">
                  <img
                    className="media-img common-Img"
                    src={efix95}
                    alt="efix95"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="dz-box overlay  overlay-shine"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <div className="dz-media">
                  <img
                    className="common-Img"
                    src={dieselEuro5}
                    alt="dieselEuro5"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="dz-box overlay overlay-shine"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="800"
              >
                <div className="dz-media media-img">
                  <img className="common-Img" src={efix95} alt="efix95" />
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="dz-box overlay overlay-shine"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="1000"
              >
                <div className="dz-media media-img">
                  <img className="common-Img" src={efix51} alt="efix51" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FuelEfix;
