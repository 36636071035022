import yearsExp from '../../../assets/images/HomePage/15yearsExp.jpg';
import dollar from '../../../assets/images/HomePage/dollar.svg';
import truck from '../../../assets/images/HomePage/truck.svg';
import worker from '../../../assets/images/HomePage/worker.svg';

import './index.css';

const OffersSection = () => {
  return (
    <div className="offers-section">
      <div className=" offersSectionSubtitle row align-items-center section-head style-1 desktopTitleAndText">
        <div
          className="col-lg-6 aos-item"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400">
          <h5 className="text-primary sub-title">Despre noi</h5>
          <h2 className="title">Ce oferim</h2>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xl-4 col-lg-4 m-lg-b30 aos-item aos-init aos-animate p-0 overlap-container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="400">
            <div className="dz-media about-media">
              <img
                src={yearsExp}
                alt=""
                className="aos-item"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-delay="400"
              />
              <div
                className="year-exp aos-item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="500">
                <h2 className="year text-primary">20</h2>
                <h4 className="text">
                  Ani de
                  <br />
                  experiență
                </h4>
              </div>
              <div
                className="year-exp aos-item orangeBehindBox"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="500"></div>
            </div>

            {/* comment here */}
            <div className=" offersSectionSubtitle row align-items-center section-head style-1 mobileTitleAndText">
              <div
                className="col-lg-6 aos-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400">
                <h5 className="text-primary sub-title">Despre noi</h5>
                <h2 className="title">Ce oferim</h2>
              </div>
            </div>

            {/* comment here */}
          </div>
          <div className="col-xl-8 col-lg-8">
            <div className="row align-items-center">
              <div
                className="col-lg-12 align-self-center aos-item description-items"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="800">
                <div className="inner-content">
                  <div className="icon-bx-wraper left m-b50 style-9 box-hover offersSectionHover">
                    <div className="icon-bx-sm icon-bx text-primary radius">
                      <span className="icon-cell">
                        <img className="onHover" src={truck} />
                      </span>
                      <div className="list-num">1</div>
                    </div>
                    <div className="icon-content">
                      <h6>
                        <a href="#" className="text-primary marginNone">
                          MISIUNEA Rojevas
                        </a>
                      </h6>
                      <p>
                        Să livrăm rapid produse de calitate la prețul corect al pieței. Serviciile
                        noastre susțin activitățile zilnice ale clienților noștri și oferă cea mai
                        bună valoare pe piața din domeniu. Rojevas este un “cetățean” responsabil și
                        un angajator respectat.
                      </p>
                    </div>
                  </div>
                  <div className="icon-bx-wraper left m-b50 style-9 box-hover active">
                    <div className="icon-bx-sm icon-bx text-primary radius">
                      <span className="icon-cell">
                        <img src={dollar} />
                      </span>
                      <div className="list-num">2</div>
                    </div>
                    <div className="icon-content">
                      <h6>
                        <a href="#" className="text-primary marginNone">
                          VIZIUNEA
                        </a>
                      </h6>
                      <p>
                        Să ne asigurăm ca până în 2030, toți partenerii și colaboratorii noștri să
                        își crească rata de sustenabilitate prin intermediul serviciilor și
                        produselor oferite de compania noastră.
                      </p>
                    </div>
                  </div>
                  <div className="icon-bx-wraper left m-b50 style-9 box-hover">
                    <div className="icon-bx-sm icon-bx text-primary radius">
                      <span className="icon-cell">
                        <img src={worker} />
                      </span>
                      <div className="list-num">3</div>
                    </div>
                    <div className="icon-content">
                      <h6>
                        <a href="#" className="text-primary marginNone">
                          VALORILE Rojevas
                        </a>
                      </h6>
                      <p>
                        Suntem ghidați de cinste, transparență, grijă față de mediu și punem accent
                        pe creștere profitabilă, relații pe termen lung cu clienții,
                        responsabilitate, dar și entuziasm în a învăța.
                      </p>
                    </div>
                  </div>
                </div>

                <ul className="list-square-check grid-2 borderRound">
                  <li> Varietate </li>
                  <li>Calitate superioară</li>
                  <li>Dezvoltare durabilă</li>
                  <li>Responsabilitate</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffersSection;
