import './index.css';
import React from 'react';

const SimpleTextBanner = () => {
  return (
    <div className="simpleTextBanner container-{breakpoint}">
      <img className="landWithSeeds" src="images/background/landWithSeeds.svg" />
      <img className="grain" src="images/background/rightWheat.svg" />
      <img className="landsTablet" src="images/background/terenuriTableta.png" />
      <img className="landsMobile" src="images/background/terenuriMobil.png" />

      <div className="simpleText">
        <p>
          Rojevas lucrează terenuri aflate în proprietate și arendă în nordul județului Galați pe
          raza localităților Foltești, Târgu Bujor, Vârlezi, Fârțănești, Stoicani.
        </p>
        <p>
          Sunteți proprietar de teren agricol și nu îl puteți exploata? Societatea Rojevas cumpără
          și arendează teren arabil extravilan pe raza localităților mai sus menționate!
        </p>
        <p>
          Printr-un contract de arendă, oferim posibilitatea obținerii unui venit sigur, fără consum
          de timp și resurse suplimentare proprietarilor de teren agricol.
        </p>
        <p>
          Oferim condiții avantajoase de arendă, suprafețele mai mari și comasate constituind un
          avantaj pentru arendator. Beneficiile colaborării cu Rojevas sunt:
        </p>
        <p>
          -
          <span style={{ paddingLeft: '40px' }}>
            Un parteneriat stabil și de lungă durată cu o companie Agricolă în continuă dezvoltare;
          </span>
        </p>
        <p>
          -
          <span style={{ paddingLeft: '40px' }}>
            Suntem profesioniști și serioși în îngrijirea terenurilor Agricole încredințate nouă;
          </span>
        </p>
        <p>
          -{' '}
          <span style={{ paddingLeft: '40px' }}>
            Plata arendei sau obținerea produselor din arenda este sigură;
          </span>
        </p>
        <p>
          -{' '}
          <span style={{ paddingLeft: '40px' }}>
            Dispariția cheltuielilor pe care le presupune cultivarea unui teren, pentru proprietari.
          </span>
        </p>
        <p>
          Rojevas are o echipă profesionistă de ingineri agronomi, dispune de utilaje performante și
          investește constant în sisteme de irigații. Noi vă scutim de grijile legate de cantitatea
          precipitațiilor, calitatea solului și de stârpirea dăunătorilor. Avem grijă în mod
          corespunzător de terenurile agricole încredințate nouă pentru utilizare, activitatea
          noastră fiind bazată pe principiile agriculturii durabile.
        </p>
        <img className="leftWheat" src="images/background/leftWheat.svg" />
        <div className="icon-bx-wraper">
          <i className="fas fa-phone" />
          <div>
            <h5 className="town">Contactați echipa noastră de profesioniști </h5>
            <span>
              <a className="townPhone" href={`tel:+40 754 226 610`}>
                +40 754 226 610
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTextBanner;
