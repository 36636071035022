import React from 'react';
import './GplStations.css';

function GplStations() {
  return (
    <section className="content-inner contentInner-Statii gpl-stations">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-b30">
            <div className="dz-media">
              <img
                src="images/stationsGasTankCut.jpg"
                alt=""
                className="img1 aos-item aos-init aos-animate"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-delay="400"
              />
              <div
                className="img2 aos-item aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <img src="images/stationsGasTankSide.jpg" alt="" />
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 col-md-12 m-b30 aos-item text-content"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <div className="section-head style-2">
              <h3 className="titleGplStations">Stații GPL</h3>
            </div>
            <div className="gplStationsText">
              <p className="mb-15">
                Stațiile GPL Rojevas livrează GPL marca Rompetrol. GPL-ul auto
                este un combustibil alternativ popular în întreaga lume, acesta
                fiind compus dintr-un mix de propan și butan. Beneficiile sale
                sunt: costul redus de achiziție și cantitatea redusă de emisii.
                GPL-ul auto este un carburant ecologic care arde în totalitate
                mulțumită compoziției sale.{' '}
              </p>

              <p>
                Datorită răspândirii sale și a popularității de care se bucură
                în ultimii ani, GPL-ul auto numit și LPG sau autogaz a devenit
                un produs indispensabil.
              </p>
            </div>
            <ul className="list-settings font-weight-500 text-secondary primary m-b30 gplStationsList">
              <h4 className="sub-title">Beneficii</h4>
              <div className="list-settings gplStationsListItems">
                <li>
                  Parcurgerea de distanțe lungi (fără alimentare) datorită celor
                  două rezervoare de carburant: benzină și GPL;
                </li>
                <li>Reducerea costurilor pentru combustibil;</li>
                <li>Exploatare în condiţii de siguranţă;</li>
                <li>
                  Motorul funcționează mai silențios pe GPL decât pe benzină.
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GplStations;
