import React, { useState } from 'react';
import './index.css';
import phone from '../../../assets/images/GasTank/phone.svg';
import facebookIcon from '../../../assets/images/GasTank/facebookIconV2.png';

import MapComponent from '../../common/MapInfoComponentV2/MapComponent';

const MapV1 = (props) => {
  const [clickedItem, setclickedItem] = useState(
    props.locations && props.locations[0]
  );

  const InfoBox = ({ clickedItem }) => {
    return (
      <div className="dz-form bg-primary style-1 infoBoxV1">
        <p className="mapV1title">{clickedItem.name}</p>
        <div className="layoutMapText">
          <img className="mapV1Icon" src={phone} />
          <p className="mapV1p"> {clickedItem.phoneNumber}</p>
        </div>
        <button className="btn shadow-primary btn-primary layoutMapText link">
          <img className="mapV1Icon facebookIcon" src={facebookIcon} />
          <a href={clickedItem.facebookLink} target={'_blank'}>
            Facebook
          </a>
        </button>
      </div>
    );
  };

  return (
    <section className="bg-light mainSection mapV1Wrapper">
      <div className="container-inner">
        <div className="row">
          <div className="section-head style-1 onMobile">
            <h6 className="text-primary sub-title">Contactează-ne</h6>
            <h2 className="title titleResponsive">Ia legătura cu noi</h2>
          </div>
          <div className="col-lg-6 col-md-12 map-container">
            <MapComponent
              locations={props.locations}
              onMarkerClick={(peco) => setclickedItem(peco)}
              coordinates={{
                lat: 45.72,
                lng: 27.03,
                zoom: 8,
              }}
            />
          </div>
          <div
            className="col-lg-6 col-md-12 aos-item p-0 map-info"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="600"
          >
            <div className="section-head style-1 onDesktop">
              <h6 className="text-primary sub-title">Contactează-ne</h6>
              <h2 className="title titleDesktop">Ia legătura cu noi</h2>
            </div>
            <InfoBox clickedItem={clickedItem} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapV1;
