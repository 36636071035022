import React from 'react';
import './index.css';
import MapV1 from '../../common/MapInfoComponentV1';
import TextWithTitle from '../../common/TextWithTitle';
import TextWithImage from '../../common/TextWithImage';
import SliderGallery from '../../common/SliderGallery';
import TextWithAnimation from '../../common/TextWithAnimation';
import BannerWithCenterText from '../../common/BannerWithCenterText';

const gastankLocations = [
  {
    name: 'BACAU (şi împrejurimi)',
    phoneNumber: '0752 300 003',
    location: { lat: 46.56, lng: 26.83 },
    facebookLink: 'https://www.facebook.com/rojevasbacau',
  },
  {
    name: 'BARLAD',
    phoneNumber: '0756039844',
    location: { lat: 46.23, lng: 27.63 },
    facebookLink: 'https://www.facebook.com/Livrari-butelii-B%C3%A2rlad-Rojevas-108677284137323/',
  },
  {
    name: 'GALATI',
    phoneNumber: '0756 039 848',
    location: { lat: 45.43, lng: 28.01 },
    facebookLink: 'https://www.facebook.com/livrarebutelii.galati.7',
  },
  {
    name: 'Târgu Ocna (şi împrejurimi)',
    phoneNumber: '0752 300 003',
    location: { lat: 46.27, lng: 26.57 },
    facebookLink: 'https://www.facebook.com/rojevasbacau',
  },
  {
    name: 'Buhuşi (şi împrejurimi)',
    phoneNumber: '0752 300 003',
    location: { lat: 46.72, lng: 26.68 },
    facebookLink: 'https://www.facebook.com/rojevasbacau',
  },
  {
    name: 'Târgu Neamţ (şi împrejurimi)',
    phoneNumber: '0752 300 003',
    location: { lat: 47.21, lng: 26.35 },
    facebookLink: 'https://www.facebook.com/rojevasbacau',
  },
];

export default () => {
  return (
    <>
      <BannerWithCenterText />
      <TextWithAnimation />
      <SliderGallery />
      <TextWithImage />
      <TextWithTitle />
      <MapV1 locations={gastankLocations} />
    </>
  );
};
