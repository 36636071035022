import React from 'react';

import MasonryImages from '../../../common/MasonryImages';
import Clients from '../../../common/Clients';
import SimpleBanner from '../../../common/SimpleBanner';
import SimpleTextBanner from '../../../common/SimpleTextBanner';

const Lands = () => {
  const images = {
    'col-xl-6 col-md-6 col-sm-6 m-b30': [
      'images/greenLand.jpg',
      'images/dayLand.jpg',
    ],
    'col-xl-6 col-md-6 col-sm-6 m-b30 s': [
      'images/twilightLand.jpg',
      'images/brownLand.png',
    ],
  };
  return (
    <>
      <SimpleBanner
        image="images/background/landsBannerV2.jpg"
        title="TERENURI"
      />
      <SimpleTextBanner />
      <MasonryImages images={images} />
      <Clients />
    </>
  );
};

export default Lands;
