import React from 'react';
import './index.css';
import luggage from '../../../assets/images/GasTank/luggage.png';

const TextWithImage = () => {
  return (
    <>
      <section className="bg-secondary counter-area-1 textWithImage">
        <div className="container biggerHeight">
          <div className="row">
            <div
              className="col-lg-5 col-md-6 aos-item content-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200">
              <div className="section-head style-4 text-white">
                <h2 className="title mb-4">Butelii propan</h2>
                <p>
                  Propanul îmbuteliat în recipiente de 84L și 26L este o soluție practică, fiind un
                  produs sigur și fiabil, la un preț optim. Propanul are diverse aplicații în
                  agricultură, turism, industrie sau uz casnic. Este ecologic și ușor de
                  transportat, fiind o alternativă economică față de alte surse convenționale de
                  încălzire. Propanul este o soluție pentru consumatorii din zonele fără acces la
                  rețeaua de distribuție de gaze naturale și nu numai. Propanul se poate utiliza la
                  temperaturi scăzute de până la -41 de grade Celsius și se poate folosi pentru
                  nevoi casnice dar și industrial.
                </p>
              </div>
              <div className="history-row m-b30">
                <ul className="list-square-check grid-4 squareCheck">
                  <li> Recipientul metalic</li>
                  <li>Cantitate 35 Kg sau 10 Kg</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 content-inner counter-col align-self-center">
              <div className="counter-inner row">
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img className="luggageIcon" src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">10</h2>
                    </div>
                    <span className="icon-span">Kg</span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">35</h2>
                    </div>
                    <span className="icon-span">Kg</span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img className="luggageIcon" src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">10</h2>
                    </div>
                    <span className="icon-span">Kg FL</span>
                  </div>
                </div>
                <div
                  className="col-md-12 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="800"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="media-full bg-img-wrapper"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-secondary counter-area-1 textWithImage" style={{ marginTop: '100px' }}>
        <div className="container biggerHeight">
          <div className="row">
            <div
              className="col-lg-5 col-md-6 aos-item content-inner"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200">
              <div className="section-head style-4 text-white">
                <h2 className="title mb-4">Mic Vrac</h2>
                <p>
                  ROJEVAS comercializează Rezervoare GPL metalice fabricate din OŢEL de înaltă
                  calitate echipate cu elemente de siguranţă. Rezervorul GPL poate fi folosit în mai
                  multe domenii: Rezidenţial, Agricol, Industrial, Comercial. Atunci când racordarea
                  la reţeaua de Gaze este imposibilă dar vă doriţi independenţă energetică, preţ
                  redus la energie şi confort soluţia pentru dumneavoastră este: “Rezervorul GPL”.
                  Folosirea GPL-ului din rezervoare GPL oferă maximul de confort şi este similară cu
                  folosirea gazului metan din reţea.
                </p>
                <p>
                  <strong>
                    Livrăm rapid și la cele mai înalte standarde de siguranță GPL sau PROPAN pentru
                    rezervorul dumneavoastră.
                    <b />
                  </strong>
                </p>
                <p className="simpleTextSingle">Ia legătura cu specialiștii noștri</p>

                <div class="icon-bx-wraper left m-b40 style-7">
                  <div class="icon-bx-sm">
                    <span class="icon-cell ">
                      <i class="flaticon-phone-call"></i>
                    </span>
                  </div>
                  <div class="icon-content">
                    <h4 class="dz-title formSubtitle">Telefon</h4>
                    <a className="townPhone" href={`tel:0754 226 610`}>
                      0754 226 610
                    </a>
                  </div>
                </div>
              </div>
              <div className="history-row m-b30">
                <ul className="list-square-check grid-4 squareCheck">
                  <li>Recipient metallic</li>
                  <li>Servicii de instalare</li>
                  <li>Documentatie autorizare</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 content-inner counter-col align-self-center">
              <div className="counter-inner row">
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img className="luggageIcon" src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">1750</h2>
                    </div>
                    <span className="icon-span">Litri</span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">2750</h2>
                    </div>
                    <span className="icon-span">Litri</span>
                  </div>
                </div>
                <div
                  className="col-md-12 col-sm-6 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400">
                  <div className="icon-bx-wraper left style-4 m-b30">
                    <div className="icon-lg">
                      <span className="icon-cell text-primary">
                        <img className="luggageIcon" src={luggage} />
                      </span>
                    </div>
                    <div className="icon-content">
                      <h2 className="dz-title counter">5000</h2>
                    </div>
                    <span className="icon-span">Litri</span>
                  </div>
                </div>
                <div
                  className="col-md-12 aos-item"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="800"></div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 rezervor-tank-col">
              <div className="media-full bg-img-wrapper rezervor-tank"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TextWithImage;
