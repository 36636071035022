import './index.css';

const BannerWithColumns = () => {
  return (
    <div className=" bannerWithColumns counter-area1 half-bg">
      <div className="container bannerColor">
        <div className="counter-inner bg-primary bannerWithColumns">
          <div className="row">
            <div
              className="col-lg-3 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
            >
              <div className="icon-bx-wraper style-12 m-b30" data-name="789">
                <div className="counterWrapper">
                  <h2 className="counter">100</h2>
                  <span className="counterText">K</span>
                </div>
                <div className="align">
                  <h6 className="title"> Clienți </h6>
                  <h6 className="title"> Persoane fizice </h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <div className="icon-bx-wraper style-12 m-b30" data-name="158">
                <div className="counterWrapper">
                  <h2 className="counter">3</h2>
                  <span className="counterText">K</span>
                </div>
                <div className="align">
                  <h6 className="title"> Clienți </h6>
                  <h6 className="title"> Persoane juridice </h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              <div className="icon-bx-wraper style-12 m-b30" data-name="874">
                <h2 className="counter">3</h2>
                <div className="align">
                  <h6 className="title">Depozite</h6>
                  <h6 className="title">regionale de</h6>
                  <h6 className="title">butelii</h6>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 aos-item"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
            >
              <div className="icon-bx-wraper style-12 m-b30" data-name="987">
                <h2 className="counter">19</h2>
                <div className="align">
                  <h6 className="title">Stații PECO</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWithColumns;
